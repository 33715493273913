.gameselect{

    width: 100%;
    height: 85px;
    background-color:#232223;
    color: #969696;

    padding: 0px;
    margin-bottom: 15px;
    box-shadow: 0px 3px 8px -8px #000;

}

body{

    background-color: #1b191b;
    
}

header{

    background: #232223 !important;
    height: 50px;

}

nav#main-nav>ul>li>button{

    padding: 0px 0px !important;
    line-height: 50px !important;

}

.logotype .imglogo{

    top:0px !important;
    height: 74px !important;
    margin-top: -3px !important;

}

#logo{

    width: 235px;
    height: 70px;
    box-shadow: 0px 2px 7px -5px #000;
    background-image: linear-gradient(180deg, #232223 -106%, #0983ac 121%);

}


.buttonEnterGame{

    margin-top: 24px; 
    font-size: 15px;
    font-weight: lighter;
    background-color: #464646;
    border:none !important;
    box-shadow: 0px 0px 0px 0px !

}

.buttonEnterGame:hover{

    background-color: #5f5c5c;
    border:none !important;
    box-shadow: 0px 0px 0px 0px !
    
}

.projectNamePrin{}

.projectNamePrin2{

    margin-top:18px;
    color:#fff;
    font-weight: 500; 

}

.btn {
    position: relative;
    text-transform: none;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    letter-spacing: .025em;
    font-size: .875rem;
    will-change: transform;
}

.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
 
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control {
      
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
 
}

.div_edit_block{

    background-color: #232223;
    margin-bottom: 10px;  
    color:#fff;
    padding:10px;
    box-shadow: 0px 2px 10px -9px #000;

}

.div_edit_block div{ padding-top: 2px; }

.div_edit_block_modal{
    
    z-index: 9999;
    height: 25px; 

}

.logoReactNative{

    height:39px !important;
    position: relative;
    top: 17px !important;
    margin-left: 35px;

}

.fontReactNativelogocima{

    color: #fff;
    top: 25px;
    position: relative;
    left: 8px;
    font-size: 18px;
    
}

.notsave{

    font-weight: 500 !important;

}

.savesave{

    font-weight: 700 !important;

}

.treefolder > ul{

    margin-top: 10px !important;

}



.f_tcp{

    color: #fff;
    cursor: pointer;
    font-weight: 600;
    margin-top: 6px;

}





/* Customize the label (the container) */
.containerCheck {
    
    position: absolute;
    left: calc(100% - 115px);
    top: 3px;
    padding-left: 35px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .containerCheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 22px;
    width: 23px;
    background-color: #eee;
  }
  

  .treefolder, .treefolder ul{

    background-color: #232429 !important;

  }
 
  
  /* On mouse-over, add a grey background color */
  .containerCheck:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .containerCheck input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containerCheck input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containerCheck .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.input_change_link{ 
      
    width: 100%;
    background-color: #202124;
    color: white;
    font-weight: 500;
    border: none;

    text-align: center;

}

.treefolder > ul > li > div > ul > li > div{

    background: #232429 !important;
    background-color: #232429 !important;

} 
 
.treefolder > ul > li > div > ul > li > div > ul > li > div{
    
    background: #232429 !important;
    background-color: #232429 !important;

}


.bt_treefold{

    height: 19px;
    font-size: 12px;
    padding-top: 0px;
    font-weight: 500;

}

.select_toolbox{
    height: 26px;
    padding: 0px 3px 0px 14px;
    width: 100%;
    border-radius: 0px 6px 0px 0px;
    margin-top: 9px;
    

}


.celular{

    width: 393px;
    margin: 0 auto;
    padding: 10px;
    position: relative; 

}
 



 

.celular_3{

    background-color: #fff;
    position: relative;
    height: 100%;
    border-radius: 10px;
    box-shadow: inset 0px 0px 3px -1px #fff;

}

.iframeTp{

    position: absolute;
    left: 32px;
    top: 31px;
    border-radius: 30px;
    width: 330px;
    height: 708px;
    border: none;
    padding-left: 2px;
    padding-right: 2px;

}

.treefolder{

    opacity: 0.3;
    pointer-events: none;

}

/*.celular_1{

    background-image: linear-gradient(to bottom, #444444, #5B5B5B 20px);
    position: relative;
    height: 100%;
    border-radius: 10px;
    padding-top: 2px;
    padding-bottom: 2px;

}

.celular_2{

    background-color: #000;
    position: relative;
    height: 100%;
    border-radius: 10px;
    padding-top: 7px;
    padding-bottom: 10px;

}*/